<template>
  <DebugConsole v-if="debugMode" />
  <web-login v-if="!host.match(/^localhost:[\d]{1,4}/)" />
  <router-view v-if="auth || host.match(/^localhost:[\d]{1,4}/)" />
</template>

<script>
import { ref, provide } from "vue";
import WebLogin from "./views/login/web-login.vue";
import DebugConsole from "./common/debug-console.vue";
import Logger from './common/logger';

export default {
  name: "main-app",
  setup() {
    const idle = ref(false);
    const filled = window.sessionStorage.userSettings ? true : false;
    const profileFilled = ref(filled);
    const auth = ref(window.sessionStorage.auth);
    function updateAuth() {
      auth.value = true;
      window.sessionStorage.auth = true;
    }
    function updateIdle() {
      idle.value = !idle.value;
    }
    function updateProfileFilled(value) {
      profileFilled.value = value;
    }
    provide("profileFilled", { profileFilled, updateProfileFilled });
    provide("idle", { idle, updateIdle });
    provide("auth", { auth, updateAuth });

    return {
      auth,
      idle,
      currentUser: undefined,
    };
  },
  data() {
    let debugMode = false;
    let appVersion = "0.1.1";
    let appTitle = `Version ${appVersion}`;

    return {
      vitalSignObject: undefined,
      healthObject: undefined,
      host: location.host,
      appVersion: appVersion,
      appTitle: appTitle,
      debugMode: debugMode,
      debugMessage: "[ Your debug message ]",
      doneLoading: {
        faceDetectionView: false,
        system: true,
      },
    };
  },
  mounted() {
    if (this.debugMode) {
      Logger.log(`%c< ${this.appTitle} > DEBUG MODE`, "color: red");
    } else {
      Logger.log(`< ${this.appTitle} >`);
    }
  },
  components: {
    WebLogin,
    DebugConsole,
  },
};
</script>
