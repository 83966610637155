<template>
  <div data-amplify-authenticator>
    <div data-amplify-container>
      <div data-amplify-router>
        <div data-amplify-router-content class="qr-scanner-container">
          <h3 class="amplify-heading mb-3">Welcome</h3>

          <div>
            <p>Scan your QR Code to login</p>
          </div>

          <div class="qr-camera w-100 h-100">
            <div v-if="logging" class="loading d-flex justify-content-center align-items-center flex-column w-100 h-100">
              <div>
                <img class="img" src="../home/assets/icon.gif" alt="loading..." width="100" />
                <p class="text-white">Logging you in..</p>
              </div>
            </div>
            <qrcode-stream
              :track="paintOutline"
              :constraints="{ facingMode }"
              @error="onError"
              @detect="onDetect"
              :style="{ transform }"
            />
          </div>

          <div class="mt-3">
            <!-- <button
             v-if="devices && devices.length > 1"
              @click="switchCamera"
              class="amplify-button amplify-button--link amplify-button--small amplify-field-group__control amplify-authenticator__font"
              data-fullwidth="false"
              data-size="small"
              data-variation="link"
              type="button"
              style="font-weight: normal"
            >
              Switch to {{ text }} camera
            </button> -->

            <button
              @click="emailLogin"
              class="amplify-button amplify-button--link amplify-button--small amplify-field-group__control amplify-authenticator__font"
              data-fullwidth="false"
              data-size="small"
              data-variation="link"
              type="button"
              style="font-weight: normal"
            >
              Login with email
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { newUserManager } from "../../user/company-user-manager.ts";
import { QrcodeStream } from "vue-qrcode-reader";
import { cloudBackend } from "../../user/company-user-manager-definitions.ts";
import { Auth } from "aws-amplify";
import adapter from "webrtc-adapter";

export default {
  setup() {},
  emits: ["emailLogin"],
  components: {
    QrcodeStream,
  },
  data() {
    return {
      facingMode: "user",
      transform: "scaleX(-1)",
      // devices: undefined,
      // text: "back",
      logging: false
    };
  },
  // async mounted() {
  //   const checkDevice = setInterval(async () => {
  //     let devices = await navigator.mediaDevices.enumerateDevices();
  //     devices = devices.filter((device) => device.kind === "videoinput");
  //     this.devices = devices.map((device, index) => {
  //       return { text: device.label, id: device.deviceId, deviceIndex: index };
  //     });
  //     if (this.devices[0].text !== '') {
  //       clearInterval(checkDevice)
  //     }
  //   }, 200);
  // },
  methods: {
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    onError(error) {
      console.error(error);

      if (error.name === "OverconstrainedError") {
        if (this.facingMode == "user") {
          alert("No front camera is detected");
        } else if (this.facingMode == "environment") {
          alert("No back camera is detected");
        }
      }
    },
    emailLogin() {
      this.$emit("emailLogin");
    },
    async onDetect(detectedCode) {
      this.logging = true;
      let currentCloudBackend = cloudBackend.aws;
      const loginInfo = CryptoJS.AES.decrypt(
        detectedCode[0].rawValue,
        "Guardian-SG-2023"
      )
        .toString(CryptoJS.enc.Utf8)
        .split("/");
      const email = loginInfo[0];
      const password = loginInfo[1];

      try {
        const user = await Auth.signIn(email, password);
        await newUserManager.ready(cloudBackend.aws);
        await newUserManager.login(currentCloudBackend);
        this.$router.push("/input-profile");
        route;
      } catch (error) {
        console.log("error signing in", error);
      }
    },
    // switchCamera() {
    //   if (this.facingMode == "user") {
    //     this.facingMode = "environment";
    //     this.text = "front";
    //   } else if (this.facingMode == "environment") {
    //     this.facingMode = "user";
    //     this.text = "back";
    //   }
    //   this.changeTransform();
    // },
    // changeTransform() {
    //   const browser = adapter.browserDetails.browser;
    //   var facingMode;
    //   if (browser == "firefox") {
    //     facingMode = document
    //       .getElementsByTagName("video")[0]
    //       .srcObject.getVideoTracks()[0]
    //       .getSettings().facingMode;
    //   } else {
    //     facingMode = document
    //       .getElementsByTagName("video")[0]
    //       .srcObject.getVideoTracks()[0]
    //       .getCapabilities().facingMode;
    //   }
    //   if (facingMode && facingMode[0] === "environment") {
    //     this.transform = "scaleX(-1)";
    //   } else {
    //     this.transform = "none";
    //   }
    // },
  },
};
</script>

<style scoped>
.loading {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}
.qr-scanner-container {
  padding: 30px;
}
.qr-camera {
  position: relative;
  aspect-ratio: 1;
}
</style>