<template>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
        <div class="modal-header">
            <h1 class="fs-3"> {{ t('labels.user_profile') }} </h1>
            <div class="modal-close-button close">
                <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="disagreeTnC"
                ></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="inputContainer">
                <!-- Personal Information -->
                <div class="row mb-3">
                <div class="fs-5 subheading form-heading">
                    {{ t("user_info.personal_information") }}
                </div>
                </div>
                <form v-on:submit="saveSettings">
                <div class="row mb-3">
                    <!-- Name -->
                    <div class="col-6">
                        <label for="nameInput" class="form-label">
                            {{ t("user_info.name") }}
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <div class="col-6">
                        <input
                            class="form-control"
                            type="text"
                            id="nameInput"
                            v-model="this.info.name"
                            @input="checkDisabled"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <!-- Age -->
                    <div class="col-6">
                        <label for="ageInput" class="form-label">
                            {{ t("user_info.age") }}
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <div class="col-6">
                        <input
                            type="number"
                            class="form-control"
                            id="ageInput"
                            v-model="this.info.age"
                            @input="checkDisabled"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <!-- Email -->
                    <div class="col-6">
                        <label for="emailInput" class="form-label">
                            {{t('health_report.email')}}
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <div class="col-6">
                        <input
                            class="form-control"
                            type="email"
                            id="emailInput"
                            v-model="this.info.email"
                            @input="checkDisabled"
                        />
                    </div>
                </div>
                <!-- Gender -->
                <div class="row mb-3">
                    <div class="col-6">
                        <label for="genderInput" class="form-label">
                            {{ t("user_info.gender") }}
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <div class="col-6">
                        <select
                            class="form-select"
                            id="genderInput"
                            @change="checkDisabled"
                            v-model="this.info.gender"
                        >
                            <option @change="checkDisabled" value=""></option>
                            <option @change="checkDisabled" value="male">
                            {{ t("user_info.male") }}
                            </option>
                            <option @change="checkDisabled" value="female">
                            {{ t("user_info.female") }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- Height & Weight -->
                <div class="row mb-3">
                    <!-- Height -->
                    <div class="col-6">
                        <label for="heightInput" class="form-label">
                            {{ t("user_info.height") }}
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <div class="col-6">
                        <input
                            type="number"
                            class="form-control"
                            id="heightInput"
                            v-model="this.info.height"
                            @input="checkDisabled"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <!-- Weight -->
                    <div class="col-6">
                        <label for="weightInput" class="form-label">
                            {{ t("user_info.weight") }}
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <div class="col-6">
                        <input
                            type="number"
                            class="form-control"
                            id="weightInput"
                            v-model="this.info.weight"
                            @input="checkDisabled"
                        />
                    </div>
                </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary button-big" :disabled="disabled" data-bs-dismiss="modal" @click="updateProfile">
                {{ t("button.continue") }}
            </button>
        </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
    setup() {
      const { t, locale } = useI18n();
      return { 
        t, locale
      };
    },
    emits: ["updateProfile", "updateAgreeTnC"],
    mounted() {
      setTimeout(() => {
        this.loadForm();
        this.checkDisabled();
      }, 300);
    },
    props: {form: Object},
    methods: {
      loadForm() {
          this.info = this.form;
          if (this.debug) {
            this.info = {
                name: "DebugUser",
                email: "debug@panoptic.ai", 
                phoneNumber: "12345678",
                birthday: "2019-08-19",
                age: 3,
                gender: "male",
                height: 170,
                weight: 70,
                unitSystem: false,
                countries: "Hong Kong",
                smoker: false,
                hypertension: false,
                bloodPressureMedication: false,
                diabetic: "No",
                heartDisease: false,
                depression: false,
            }
        }
      },
      checkDisabled() {
        let check = false;
        const required = ['name', 'age', 'gender', 'email', 'height', 'weight'];
        Object.keys(this.info).map((data) => {
          if (!required.includes(data)) {
            return;
          }
          if (this.info[data] === null || this.info[data] === "") {
            check = true;
            return;
          }
        });
        this.disabled = check;
      },
      updateProfile() {
        this.$emit("updateProfile", this.info);
        // window.localStorage.userProfile = JSON.stringify(this.form)
      },
      disagreeTnC() {
        this.$emit('updateAgreeTnC', false)
      }
    },
    computed: {
      // showGuide() {
      //     console.log("show guide")
      //     return 0
      // }
    },
    watch: {
      form(newValue) {
          this.info = newValue;
          // console.log(newValue);
      }
    },
    data() {
      return {
        info: {
          name: "",
          email: "",
          phoneNumber: "",
          birthday: "",
          age: "",
          gender: "",
          unitSystem: false,
          countries: "",
          height: "",
          weight: "",
          smoker: false,
          hypertension: false,
          bpMedication: false,
          diabetic: "",
          heartDisease: false,
          depression: false,
        },
        disabled: true, 
        debug: this.$root.debugMode,
        agreeTnC: false
      };
    },
}
</script>


<style scoped>
.modal {
    overflow: hidden;
    max-height: 60%;
    max-width: 98%;
    left: 1%;
    top: 20%;
    border-radius: 10px;
}
.modal-dialog {
    width: 100%;
    /* height: 100%; */
}
.modal-close-button {
    position: absolute;
    right: 4.5%;
}
/* Modal Content/Box */
.modal-content {
    position: grid;
    background-color: #fefefe;
    margin: 0% auto;
    padding: 5px;
    border: 1px solid #888;
    /* min-height: 95%; */
    min-width: 100%;
    overflow: hidden;
}
.modal-header {
    display: flex;
    height: 15%;
    justify-content: center;
    font-size: xx-large;
}
.modal-body {
    display: grid;
    width: 100%;
    padding: 20px;
    margin: 5px auto;
}
.modal-footer {
    bottom: 0px;
    height: 15%;
    justify-content: center;
}
.inputContainer {
    width: 100%;
}
.form-heading {
    font-size: x-large;
    font-weight: bold;
    text-decoration: underline;
}
.button-big {
    /* min-height: 50px; */
    height: 80%;
    min-width: 100px;
    font-size: large;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>