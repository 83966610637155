<template>
  <div class="d-flex flex-row mb-2">
    <div
      class="col bg-white product-card d-flex flex-column justify-content-between"
      v-for="product in recommendation[productType].products"
      :key="product"
    >
      <div>
        <img :src="require('' + product.image)" alt="" class="product-image" />
        <div class="product-name">
          {{ product.name }}
        </div>
        <div
          v-if="product.description.length < 100"
          class="product-description"
        >
          {{ product.description }}
        </div>

        <div v-else class="product-description">
          {{ product.description.substring(0, 100) + "..." }}
        </div>
      </div>
      <div>
        <a
          target="_blank"
          :href="product.link"
          class="buy-button d-flex flex-row justify-content-center align-items-center"
        >
          View Deal
          <img src="./assets/buy-arrow.svg" alt="" class="buy-arrow" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    productType: String,
  },
  data() {
    return {
      recommendation: {
        generalWellness: {
          title: "General Wellness",
          products: [
            {
              name: "Guardian Multivitamin",
              image: "./assets/guardian-multivitamin.jpeg",
              description:
                "Multivitamin Minerals Passionfruit Flavour, 10 Effervescent Tabs (Sugar-Free)",
              price: "4.70",
              link: "https://www.guardian.com.sg/guardian-multivitamin-minerals-passionfruit-flavour-10-effervescent-tabs-sugar-free/p/621256",
            },
            {
              name: "Go Healthy",
              image: "./assets/go-multi-vitamin.jpeg",
              description:
                "GO Healthy Multi Everyday, 60 capsules GO Multi Everyday works to support energy levels and maintain general health and well-being.",
              price: "29.70",
              link: "https://www.guardian.com.sg/go-healthy-multi-everyday-60-capsules/p/604268",
            },
            {
              name: "Centrum Advance",
              image: "./assets/centrum-advance.jpeg",
              description: "Centrum Advance Multivitamin, 60 tablets",
              price: "36.70",
              link: "https://www.guardian.com.sg/centrum-advance-multivitamin-60-tablets/p/000276",
            },
          ],
        },
        stress: {
          title: "Stress Level",
          products: [
            {
              name: "Guardian Vitamin B",
              image: "./assets/guardian-vitamin-b-1.jpeg",
              description: "Guardian Vitamin B Complex 60 Tablets",
              price: "10.85",
              link: "https://www.guardian.com.sg/guardian-vitamin-b-complex-60-tablets/p/620593",
            },
            {
              name: "Guardian Vitamin B",
              image: "./assets/guardian-vitamin-b-2.jpeg",
              description:
                "Guardian Vitamin B-Complex + Vitc + Minerals Effervescent, 15 Tabs",
              price: "4.70",
              link: "https://www.guardian.com.sg/guardian-vitamin-b-complex-vitc-minerals-effervescent-15-tabs/p/621253",
            },
            {
              name: "Shaklee B-Complex",
              image: "./assets/shaklee-b-complex.jpeg",
              description: "Shaklee B-Complex, 120s",
              price: "29.30",
              link: "https://www.guardian.com.sg/shaklee-b-complex-120s/p/126546",
            },
          ],
        },
        facialSkinAge: {
          title: "Facial Skin Age",
          products: [
            {
              name: "Blackmores",
              image: "./assets/blackmores.jpeg",
              description: "Blackmores Ultimate Radiant Skin 30s",
              price: "55.55",
              link: "https://www.guardian.com.sg/blackmores-ultimate-radiant-skin-30s/p/617540",
            },
            {
              name: "Swisse",
              image: "./assets/swisse.jpeg",
              description: "Swisse Ultiboost Hair Skin Nails 100 Tabs",
              price: "51.55",
              link: "https://www.guardian.com.sg/swisse-ultiboost-hair-skin-nails-100-tabs/p/602551",
            },
            {
              name: "VÖOST",
              image: "./assets/voost.jpeg",
              description:
                "VÖOST Hair Skin Nails Effervescent 20 tabs to help support healthy hair, skin and nails (20 count)",
              price: "23.10",
              link: "https://www.guardian.com.sg/vost-hair-skin-nails-effervescent-20-tabs-to-help-support-healthy-hair-skin-and-nails-20-count/p/627457",
            },
          ],
        },
        cardiovascular: {
          title: "Cardiovascular",
          products: [
            {
              name: "Guardian Fish Oil",
              image: "./assets/guardian-fish-oil.jpeg",
              description:
                "Guardian Odourless Omega-3 Fish Oil 1000Mg 200 Softgels",
              price: "14.85",
              link: "https://www.guardian.com.sg/guardian-odourless-omega-3-fish-oil-1000mg-200-softgels/p/620068",
            },
            {
              name: "Holistic Way",
              image: "./assets/holistic-way.jpeg",
              description:
                "Holistic Way Odourless Super Garlic (60 Vegetarian Capsules)",
              price: "26.85",
              link: "https://www.guardian.com.sg/holistic-way-odourless-super-garlic-60-vegetarian-capsules/p/170313",
            },
            {
              name: "GreenLife",
              image: "./assets/greenlife.jpeg",
              description: "GreenLife CoQ10 150mg, 30 capsules",
              price: "33.90",
              link: "https://www.guardian.com.sg/greenlife-coq10-150mg-30-capsules/p/610137",
            },
          ],
        },
      },
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Montserrat:wght@600&display=swap");

.buy-arrow {
  height: 17px;
  margin-left: 8px;
}
.buy-button {
  padding: 8px 0;
  border-radius: 13px;
  background: #18a661;
  width: 100%;
  color: white;
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
}
.close-icon {
  height: 20px;
}
.guardian-logo {
  width: 108px;
}
.product-card {
  justify-content: center;
  text-align: center;
  margin: 0 20px;
}
.product-description {
  overflow: hidden;
  color: var(--carbon-800, #363636);
  text-align: center;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
}
.product-image {
  height: 100px;
  margin: 20px 0;
}
.product-name {
  color: var(--carbon-800, #363636);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.product-price {
  color: #ff7a00;
  font-family: Inter;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 10px;
}
</style>