<template>
    <div class="chart p-0" ref="resizeRef">
        <svg ref="svgRef"></svg>
    </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import * as d3 from "d3";
import useResizeObserver from "./resizeObserver";
import { useI18n } from "vue-i18n";
import { checkCondition, getRange } from "./vitalRangeChecker";
// import { newUserManager } from "../../user/company-user-manager.ts";


export default {
    props: {
        data: String,
        tag: String,
        dataRange: Number,
        min: Number,
        max: Number,
        unit: String,
        pdf: Boolean,
        font: String
    },
    setup(props) {
        const { t, locale } = useI18n();
        const {resizeRef, resizeState} = useResizeObserver();
        const svgRef = ref(null);

        onMounted(async () => {
            const svg = d3.select(svgRef.value);
            const linearGradientName = `linear-gradient-${props.tag}${props.pdf ? '-pdf' : ""}`

            watchEffect(() => {
                svg.selectAll("*").remove();
                var {width, height} = resizeState.dimensions;
                svg.attr('width', width).attr('height', props.pdf ? 300 : 125)
                .append('defs')

            svg.append('style')
                .attr('type', 'text/css')
                .text("@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700;800&display=swap');");

                svg.append("linearGradient")
                    .attr("id", linearGradientName)
                    .attr("x1", 0)
                    .attr("y1", 0)
                    .attr("x2", 1)
                    .attr("y2", 0)
                    .selectAll("stop")
                        .data(getRange(props.tag, props.min/props.dataRange, props.max/props.dataRange, props.dataRange))
                    .enter().append("stop")
                        .attr("offset", function(d) { return d.offset; })
                        .attr("stop-color", function(d) { return d.color; });

                svg.append('rect')
                    .attr('x', 0)
                    .attr('y', height*0.75/2)
                    .attr('width', width)
                    .attr('height', props.pdf ? 40 : 15)
                    .attr('rx', props.pdf ? 10 : 5)
                    .attr('ry', props.pdf ? 10 : 5)
                    .attr('fill', 'url(#' + linearGradientName + ')')

                svg.append('text')
                    .attr('class', 'rangefw-lighter')
                    .attr('font-size', props.pdf ? '35px' : '14px')
                    .attr('x', 0)
                    .attr('y', height*2/3)
                    .attr('text-anchor', 'start')
                    .style('fill', '#747579')
                    .style('font-family', props.font)
                    .text((props.min != 0 ? "< "  : "") + props.min)

                svg.append('text')
                    .attr('class', 'range fw-lighter')
                    .attr('font-size', props.pdf ? '35px' : '14px')
                    .attr('x', width)
                    .attr('y', height*2/3)
                    .attr('width', width)
                    .attr('text-anchor', 'end')
                    .style('fill', '#747579')
                    .style('font-family', props.font)
                    .text((props.max != props.dataRange ? "> "  : "") + props.max)

                const textCondition = (value) => {
                    let text = ''
                    if (value == 0 || value == props.dataRange) {
                        text = checkCondition(props.tag, value).split('_')[0];
                    } else if (value > props.min) {
                        text = checkCondition(props.tag, value + 1).split('_')[0];
                    } else if (value < props.max) {
                        text = checkCondition(props.tag, value - 1).split('_')[0];
                    }
                    return t('health_report.'+text)
                }

                svg.append('text')
                    .attr('class', 'range fw-lighter')
                    .attr('font-size', props.pdf ? '35px' : '14px')
                    .attr('x', 0)
                    .attr('y', height*3.25/4)
                    .attr('text-anchor', 'start')
                    .style('fill', '#747579')
                    .style('font-family', props.font)
                    .text(textCondition(props.min))

                svg.append('text')
                    .attr('class', 'range fw-lighter')
                    .attr('font-size', props.pdf ? '35px' : '14px')
                    .attr('x', width)
                    .attr('y', height*3.25/4)
                    .attr('text-anchor', 'end')
                    .style('fill', '#747579')
                    .style('font-family', props.font)
                    .text(textCondition(props.max))

                let position = ((Number(props.data)-props.min) / (props.max-props.min)) * width;
                if(position > width) {
                    position = width;
                } else if (position < 0) {
                    position = 0;
                }

                let textAnchor = 'middle';
                if( position > width - 10) {
                    textAnchor = 'end';
                } else if ( position < 10) {
                    textAnchor = 'start';
                }


                svg.append('path')
                    .attr('id', 'arrow-'+props.tag)
                    .attr('d' , 'M7.92093 0.0294432L4.49036 1.67125L1.05979 0.0294432C1.0439 0.0218866 1.02759 0.0178721 1.01086 0.0172817C0.994132 0.0166913 0.977738 0.0196432 0.961678 0.0261372C0.945619 0.0326312 0.930563 0.0423132 0.916594 0.0553012C0.902542 0.0682893 0.890247 0.0838749 0.879624 0.102058C0.869085 0.120359 0.860637 0.140432 0.854363 0.162275C0.848006 0.184119 0.844242 0.206907 0.84282 0.230404C0.841482 0.2539 0.842653 0.27716 0.846334 0.300185C0.84993 0.323209 0.855953 0.345052 0.864317 0.365479L4.34783 8.87725C4.35143 8.88598 4.35536 8.89437 4.35979 8.9024C4.36414 8.91054 4.36891 8.9181 4.37401 8.9253C4.37903 8.93239 4.38447 8.93912 4.39016 8.94526C4.39585 8.95152 4.40187 8.95706 4.40806 8.96214C4.41433 8.96722 4.42069 8.97171 4.42738 8.9756C4.43399 8.9795 4.44085 8.98269 4.44779 8.9854C4.45473 8.988 4.46176 8.99001 4.46887 8.99131C4.47598 8.9926 4.48317 8.99331 4.49036 8.99331C4.49756 8.99331 4.50467 8.9926 4.51186 8.99131C4.51897 8.99001 4.526 8.988 4.53294 8.9854C4.53988 8.98269 4.54666 8.9795 4.55335 8.9756C4.55995 8.97171 4.5664 8.96722 4.57267 8.96214C4.57886 8.95706 4.58488 8.95152 4.59057 8.94526C4.59626 8.93912 4.60161 8.93239 4.60671 8.9253C4.61181 8.9181 4.61658 8.91054 4.62093 8.9024C4.62528 8.89437 4.6293 8.88598 4.63289 8.87725L8.11633 0.365479C8.12477 0.345052 8.1308 0.323209 8.13448 0.300185C8.13807 0.27716 8.13924 0.2539 8.13791 0.230404C8.13657 0.206789 8.13272 0.184119 8.12645 0.162275C8.12017 0.140314 8.11172 0.120241 8.1011 0.102058C8.09048 0.0837568 8.07818 0.0681712 8.06422 0.0551832C8.05016 0.0421951 8.03519 0.0325131 8.01905 0.0260191C8.00299 0.0195251 7.98659 0.0165733 7.96978 0.0171636C7.95305 0.017754 7.93683 0.0218866 7.92093 0.0294432Z')
                    .attr('fill', '#747579')
                    .attr('transform', props.pdf ? 'translate(' + (position-15) + ',70) scale(3.5)' : 'translate(' + (position-6) + ',30) scale(1.5)')
                
                    
                svg.append('text')
                    .attr('class', 'range fw-lighter')
                    .attr('font-size', props.pdf ? '35px' : '14px')
                    .attr('x', position)
                    .attr('y', height/5)
                    .attr('text-anchor', textAnchor)
                    .style('fill', '#747579')
                    .style('font-family', props.font)
                    .text(`${props.data}${props.unit ? props.unit : ""}`)
 
            })
        })
        return {t, locale, resizeRef, resizeState, svgRef}
    },
}
</script>

<style scoped>
svg {
    overflow: visible;
}
</style>