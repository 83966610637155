const locales = {
  En: {
    page: {
      dashboard: 'Dashboard',
      settings: 'Settings',
      help: 'Help',
      logout: 'Logout',
      coming_soon: 'Stay tuned!<br>We are Coming Soon...',
    },
    guest_signin: 'Continue as Guest',
    logout_prompt: 'Are you sure you want to log out?',
    instruction: {
      stay_still: 'Scanning in progress, stay still.',
      count_down1: 'Scanning starting in ',
      count_down2: ', stay still'
    },
    vital_signs: {
      heartRate: {
        title: 'Heart Rate',
        body: [
          "Your heart rate is {value} beats per minute, which is within the {condition} heart rate range. For most healthy adult women and men, resting heart rates range from 60 to 100 beats per minute.",
          "The interesting fact about heart rate is your heartpumps about 1.5 \
          gallons of blood every minute. Over the course of aday, that adds up to over 2,000 gallons. The average heartbeat of a woman is about 8 beats a minute \
          faster than a man's heartbeat.",
          'You get the most benefits when you exercise in your "target heart rate zone." Usually, this is when your heart rate (pulse) is 60% to 80% of your maximum.',
        ],
        excellent: 'Keep it up with your healthy lifestyle!',
        good: "You're doing awesome. Remember to do exercises and manage stress to maintain this good state.",
        poor: 'Make sure you are not scanning after a workout session! Or visit a doctor to prevent underlying diseases.',
      },
      hrvIbi: {
        title: 'HR Variability IBI',
        body: [
          "Your IBI is in {condition} condition which is around {value} ms, although it varies from person-to-person, which depends on exercising, lifestyle and biological factors.",
          "One interesting fact about IBI is that it is actually controlled by the sympathetic nervous \
          systems, which tells the heart to beat faster, and parasympathetic nervous system, which tells the heart to beat slower.",
          "Interbeat interval (IBI) is the time domain beat-to-beat variation of the electrocardiography (ECG). An optimal, usually higher level of HRV indicates the ability \
          to cope with changing environment,self-regulate, and therefore better overall wellbeing.",
        ],
        excellent: 'You are doing great!',
        good: "Remember to exercise regularly.",
        poor: 'Remember to exercise regularly and visit a doctor if any symptoms occur.',
      },
      hrvSdnn: {
        title: 'HR Variability SDNN',
        body: [
          "Your SDNN is in {condition} condition which is {value} ms, although it varies from person-to-person, which depends on exercising, lifestyle and biological factors.",
          "We can see a lot from HRV data. HRV reflects the regulation of autonomic balance, blood pressure, gas exchange, gut, heart, and vascular tone, which refers to the diameter of the blood vessels that regulate BP, and possibly facial muscles.",
          "The SD of the IBI (SDNN) is the most widely used measure of HRV. SDNN is a good indication for cardiac risk when recorded over a 24 h period. \
          SDNN values predict both morbidity and mortality. For example, patients with SDNN values under 50 ms had a 5.3 times higher risk of mortality \
          at follow-up than those with values over 100 ms.",
        ],
        excellent: 'Great job!',
        good: "Keep up the good work! Remember to sleep well and consistently.",
        poor: 'Remember to sleep early and workout regularly.',
      },
      respiratoryRate: {
        title: 'Breathing Rate',
        body: [
          "The number of breaths you take per minute is {value},  which is {condition}. Normal respiration rates for an adult \
          person at rest range from 12 to 16 breaths per minute. Normal respiration rates for an adult person at rest range from 12 to \
          16 breaths per minute.",
          "Please make sure not to check this vital sign right after exercises. \
          An Interesting fact about the respiratory system is you lose a lot of water by breathing. Humans exhale up to 17.5 milliliters of water per hour.",
          "Respiratory rate is a fundamental vital sign that is sensitive to different pathological conditions (e.g., adverse cardiac events, \
          pneumonia, and clinical deterioration) and stressors, including emotional stress, cognitive load, heat, cold, physical effort, and exercise-induced fatigue.",
        ],
        excellent: 'You are doing great!',
        good: "There you go! Remember to avoid exposure to pollutants to make it excellent.",
        poor: '',
      },
      spo2: {
        title: 'O2 Saturation',
        body: [
          "The percentage of oxygen in your blood is {value}%, which is {condition}. A normal level of oxygen is usually 95% or higher.",
          "SpO2, also known as oxygen saturation, is a measure of the amount of oxygen-carrying \
          hemoglobin in the blood relative to the amount of hemoglobin not carrying oxygen. The body needs there to be a certain level of oxygen in the blood or it will \
          not function as efficiently.",
          "Oxygen is tightly regulated within the body because hypoxemia can lead to many acute adverse effects on individual organ systems such as brain, heart, and kidneys.",
        ],
        excellent: 'You are doing great! Keep it up with your healthy lifestyle and exercise regularly!',
        good: "Remember to exercise regularly to keep the SpO2 level.",
        poor: "To enhance your health, you'd better have healthy diets. Blackberries, blueberries, and kidney beans are recommended for you.",
      },
      stress: {
        title: 'Stress Rating',
        body: [
          "Your stress rating is {value}, which is {condition}. Normal stress level is around 0-2",
          "Do you know that the stress level is controlled by the nervous system? When we are faced with danger, our central nervous systems switch into “fight or flight” mode. Our heart rates increase, blood vessels become narrower, and we become more mentally alert. These changes in our bodies prepare us for swift action, to either face the threat head-on, or run away.",
          "Stress not only affects your emotions, it is also closely related to your physical wellbeing. Stress will lead to physiological response \
          such as muscle aches and tightness as a natural defensive function to protect your body from injuries.",
        ],
        excellent: 'You are not stressed! Thank you for taking good care of your mental health and well-being!',
        good: "Remember to take a break once in a while and maintain this good state.",
        poor: 'Remember to talk to someone when you have too much on your plate. ',
      },
      temperature: {
        title: 'Temperature (Beta)',
        body: [
          "Your body temperature is now {value}°C, which falls into {condition} range.",
          "An interesting fact about temperature is that sometimes cooler skin can protect your brain. Doctors found out that lowering the body temperature right after cardiac arrest can reduce damage to the brain, and raises the chances that the person will recover.",
          "If your body temperature is above 39.4 ℃, seek your doctor as soon as possible",
        ],
        excellent: 'Remember to stay hydrated.',
        good: "You are doing a great job!. Remember to stay hydrated.",
        poor: 'Please seek medical advice!',
      },
      bloodPressure: {
        title: 'Blood Pressure (Beta)',
        body: [
          "Your blood pressure is now {value} mmHg which is {condition}. A normal blood pressure level is less than 120/80 mmHg.",
          "Usually, blood pressure starts to rise a few hours before you wake up. It continues to rise during the day\
          , peaking in midday. Blood pressure normally drops in the late afternoon and evening. Blood pressure is normally lower at night while you're sleeping",
          "A normal blood pressure level is less than 120/80 mmHg. Usually, blood pressure starts to rise a few hours before you wake up. It continues to rise during the \
          day, peaking in midday. Blood pressure normally drops in the late afternoon and evening. Blood pressure is normally lower at night while you're sleeping",
        ],
        excellent: 'Great job! Don’t let your emotions overwhelm you.',
        good: "Remember if you feel lightheaded, try to close your eyes and focus on your breathing.",
        poor: 'Meditate and forget about your worries for 5 minutes.',
      },
      bmi: {
        title: 'BMI',
        body: [
          "According to your height and weight, your BMI is {value} which is in {condition} range. For adults 20 years old and older, a healthy BMI ranges from 18.5 - 24.9.",
          "Do you know how to calculate your BMI? BMI = weight (kg) / height (m)^2",
          "If your BMI indicates you're overweight or obese, you may be at risk for certain health conditions. Those include diabetes, heart disease, \
          arthritis, and certain cancers. However, BMI can't be used to diagnose disease.",
        ],
        excellent: 'Well done!  Please continue to have a healthy diet and work out regularly.',
        good: "Remember to keep your BMI range within 18.5-24.9 by eating healthy and doing physical activities.",
        poor: '',
      },
      generalRisk: {
        title: 'CVD Risk (Beta)',
        body: [
          "Your CVD risk is now at {value}%, which falls into a {condition} risk of cardiovascular disease.",
          "Do you know that it is possible to have a broken heart? It’s called broken heart syndrome and it resembles heart attack symptoms.  A heart attack results from a cardiac condition, whereas broken heart syndrome is brought on by an increase in stress hormones from an emotional or physical stressful incident.",
          "If your CVD risk is above 15%, it indicates that you are at a high risk of cardiovascular disease. Cardiovascular diseases (CVDs) are a group of disorders of the heart and blood vessels, which include coronary heart disease, cerebrovascular disease, peripheral arterial disease, rheumatic heart disease, etc."
        ],
      },
      covidRisk: {
        title: 'Covid Risk (Beta)',
        body: [
          "Your Covid Risk is now at {value}%, which falls into a {condition} risk of covid risk.",
          "Do you know that the level of Covid risk is affected by many different interacting factors? They include age and existing health conditions such as heart disease, lung disease, diabetes, hypertension, and being overweight. [2]<br><br>The most common symptoms of COVID-19 include fever, dry cough, and fatigue. Some people may only have very mild or non-specific symptoms, while some may develop severe symptoms like shortness of breath, chest pain, or confusion. [3]",
          "If your covid risk is above [xx%] and you are feeling serious symptoms, try to seek immediate medical attention. If you feel mild symptoms, remain at home and self-isolate until you feel better."
        ],
      },
      facialSkinAge: {
        title: 'Facial Skin Age',
        body: [
          "Your facial skin age is {value}. Excellent! You are young and charming",
          "The interesting fact is that the first signs of aging start to become apparent on the surface of the skin from around the age of 25. ",
          "One of the most obvious indications of aging is skin changes. The visible signs of aging appear on your skin quickly. While healthy lifestyle choices and an effective skin care regimen can control some skin-related factors, many age-related traits are related to genetics. Below are the Seven Signs of Ageing:\
          <ul>\
            <li>\
              Fine lines and wrinkles. \
            </li>\
            <li>\
              Dullness of skin.\
            </li>\
            <li>\
              Uneven skin tone.\
            </li>\
            <li>\
              Dry skin.\
            </li>\
            <li>\
              Blotchiness and age spots. \
            </li>\
            <li>\
              Rough skin texture. \
            </li>\
            <li>\
              Visible pores.\
            </li>\
          </ul>\
          "
        ],
      },
      bloodSugar: {
        title: 'Blood Sugar',
        body: [
          "To be completed.",
          "To be completed.",
          "To be completed."
        ],
      },
      bloodAlcohol: {
        title: 'Blood Alcohol',
        body: [
          "To be completed.",
          "To be completed.",
          "To be completed."
        ],
      },
      general_wellness: {
        title: 'General Wellness',
      },
      energy: {
        title: 'Energy',
      },
      productivity: {
        title: 'Productivity'
      },
    },
    things_to_do: {
      exercise: "Exercise regularly",
      eat_right: "Eat right",
      stress: "Tackle stress",
      smoke: "Stop smoking",
      hydrate: "Stay hydrated ",
      diet: "Maintain a healthy diet",
      alcohol: "Avoid alcohol",
      sleep: "Sleep well and consistently",
      breathing: "Practice intentional breathing and mindfulness",
      green: "Add some greenery to your home",
      active: "Get fit and stay active",
      eat_well: "Eat well",
      drink: "Drink lots of water",
      fresh_air: "Get fresh air",
      drink_water: "Drink water",
      iron_food: "Eat iron-rich foods",
      train_breathing: "Train your slow and deep breathing",
      thankful: "Think of 3 things to be thankful for ",
      talk: "Talk to somone when you have too much on your plate",
      drinking: "Drinking a lot of clear liquids such as water, broth, and juices or a rehydration drink",
      bath: "Taking a lukewarm bath",
      rest: "Resting",
      cool: "Keeping yourself cool with lightweight clothing and bed coverings",
      sweat: "Sweat out by doing physical activities 30 minutes a day ",
      eat_healthy: "Eating a healthy diet ",
      meditate: "Manage stress by windling down with meditation ",
      strive_active: "Strive to be more active every day",
      veggies: "Add more veggies to your meal",
      fruits: "Swap fruits instead of sugary baked goods for desert",
      control_bp: "Keep your blood pressure and diabetes under control.",
      vaccine: "Get vaccinated.",
      distance: "Maintain a 1m distance between you and others.",
      mask: "Wear a fitted mask properly.",
      open_area: "Choose open, well-ventilated areas over closed ones.",
      wash_hands: "Wash your hands regularly with soap and water.",
      cover_cough: "When you cough or sneeze, cover your mouth and nose"
    },
    button: {
      mode: 'Mode',
      fever_percentage: 'Fever Percentage',
      fever_threshold: 'Threshold Temp.',
      start: 'START',
      stop: 'STOP',
      shutdown: 'SHUT DOWN',
      cancel: 'Cancel',
      ok: 'OK',
      all: 'ALL',
      power_on: 'POWER ON',
      full_screen: 'FULL SCREEN',
      save_settings: 'Save Settings',
      upgrade: 'Upgrade',
      change_password: 'Change Password',
      continue: 'continue',
      show_health_report: 'Show Health Report',
      clear_user_information: 'Clear User Info',
      send_to_email: 'Send to Email',
      rate_us: 'Rate Us!',
      submit: 'Submit',
    },
    labels: {
      sending: 'sending...',
      user_profile: 'User Profile',
      rate_us: 'Your feedback is valuable to us, please give us a rating!',
      poor: 'Poor',
      good: 'Good',
      dissatisfied: 'Dissatisfied',
      satisfied: 'Satisfied',
    },
    graph: {
      measurement: 'Measurement',
      time: 'Time',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      trend_line: 'Show Trend Line',
      excellent: 'Excellent',
      good: 'Good',
      poor: 'Poor'
    },
    mode: {
      normal: 'Normal',
      debug: 'Debug'
    },
    threshold: {
      auto: 'Auto'
    },
    message: {
      startup: 'System is OFF, press "POWER ON" to start.',
      loading: 'Loading',
      calibrating: 'Stay still, Calibrating ...',
      no_face_box: 'No Face Box ...',
      stopping: 'Stopping {system_short_name}...',
      confirm_shutdown: 'Are you sure you want to shutdown the system?',
      important_message: 'Important Message',
      emmisivity: '{short_name} v{version} is designed to work with FLIR A315 with emmisivity set to <span class="text-danger font-weight-bold">{emmisivity}</span>.',
      auto_sys_restart: 'Automatic System Restart',
      prevent_overheat: 'Restarting the system to prevent over-heating...',
      camera_error: 'Camera Error Detected',
      system_error: 'System Error Detected',
      camera_fatal_error: 'System automatically stopped due to camera error, please contact {contact}. Thank you.',
      system_fatal_error: 'System automatically stopped due to error, please contact {contact}. Thank you.',
      system_off: 'System is OFF.',
      tap_to_view: 'Tap to View',
      condition_check_failed: '(1) Please move into the pur ple human <br/>(2) stay still',
      coming_soon: 'Coming Soon',
      breath_in: 'In',
      breath_out: 'Out',
      bad_scan_message: 'Bad scan, please restart the scan',
      weak_signal_message: 'Weak signal',
      settings_updated: 'Settings updated',
      password_updated: 'Password updated',
      password_update_failed: 'Failed to change password, please check your password',
      fill_profile: 'Please fill in your company profile',
      conditions: {
        lighting: "Lighting",
        distance: "Distance",
        centered: "Centered",
        movement: "Movement",
        server_ready: "Server Ready"
      },
      subscribe: "Subscribe to see this content!",
      scan_quality: "Scan Quality",
      email: 'Email sent to',
      feedback: "Feedback received, Thank You!! Have a nice day~",
      camera_preparation: "We are downloading some models, please ensure your face is in front of the camera!",
      loading_wait: "Loading, please wait!",
      wait_result: "Analyzing result... Please wait..."
    },
    disclaimer: {
      disclaimer1: "Disclaimer: The software does not store any personal data.",
    },
    copyright: "\u00A9 2021-2023 PanopticAI Ltd. All Rights Reserved.",
    last_scan: 'Last Scan',
    last_update: 'Last Updated',
    vs_descriptions: {
      bloodPressure: "The normal blood pressure for an adult is below 120/80 mmHg.",
      bmi: "The normal BMI for an adult is 18.5-24.9.",
      respiratoryRate: "The normal breathing rate for an adult is 6-24 bpm.",
      generalRisk: "Risk of getting cardiovascular diseases, the smaller the better.",
      covidRisk: "Risk of getting covid-19, the smaller the better.",
      heartRate: "The normal resting heart rate for an adult is between 60-100 bpm.",
      hrvSdnn: "The normal HRV (SDNN) for an adult is above ~20ms",
      //      hrvSdnn: "The normal HRV (SDNN) for an adult is 68-219 ms throughout the whole day",
      hrvIbi: "The normal resting IBI for an adult is between 600-1000 ms.",
      spo2: "The normal O2 saturation for an adult is 95-100 %.",
      stress: "The normal resting stress rating is from 0-2.",
      temperature: "The normal body temperature for an adult is around 98.6°F (37°C).",
      facialSkinAge: "You are always young & charming ;)",
    },
    option: {
      yes: 'Yes',
      no: 'No',
      logout_yes: 'Yes',
      logout_no: 'No',
    },
    user: {
      supervisor: 'Supervisor',
      guest: 'Guest',
    },
    company_info: {
      company_information: "Company Information",
      company_id: "Company Id",
      project_id: "Project Id",
      site_id: "Site Id",
      location: "Site Location",
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
    },
    user_info: {
      user_id: 'User Id',
      profile_image: 'Profile Image',
      personal_information: 'Personal Information',
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
      name: 'Name',
      nickname: 'Nickname',
      birthday: 'Birthday',
      age: "Age",
      height: 'Height (cm)',
      weight: 'Weight (kg)',
      gender: 'Gender at birth',
      male: 'Male',
      female: 'Female',
      unit_system: 'Unit System',
      country: 'Countries',
      countryOptions: {
        hongkong: "Hong Kong, China",
        china: "Mainland China",
      },
      metric: 'Metric',
      imperial: 'Imperial',
      medical_history: 'Medical History',
      smoking: 'Do you smoke?',
      hypertension: 'Do you have hypertension?',
      bp_medication: 'Are you taking blood pressure medication?',
      diabetic: 'Are you diabetic?',
      heart_disease: 'Do you have heart disease?',
      depression: 'Do you have depression?',
      department: 'Department',
      type_1: 'Type 1',
      type_2: 'Type 2',
      plan_type: {
        free: 'Free',
        pro: "Pro",
        enterprise: 'Enterprise',
        team: 'Team',
        unlimited: 'Unlimited'
      }
    },
    password: {
      password: 'Password',
      old: 'Old Password',
      new: 'New Password',
      confirm: 'Confirm Password',
      not_match: 'Password does not match'
    },
    detailed_info: {
      history: 'History',
      trend_analysis: 'Trend Analysis',
      things_to_do: 'Things to do',
      note: 'Note',
      references: 'References',
    },
    no_data: 'No Data',
    analysis: {
      record: 'From the records on the past 7 days, your {tag} is in {quality} condition with the average of {average} {unit}',
      changing: '{conjunction} it is {slope} to a {condition} condition.',
      decrease_worse: '{conjunction} it is showing a slight downward trend. Maintain it to keep it from decreasing to a worse condition.',
      increase_worse: "{conjunction} it is showing a slight upward trend. Maintain it to keep it from highly increasing to a worse condition.",
      constant: 'From the records on the past 7 days, your {tag} is constantly in {quality} condition with the average of {average} {unit}.',
      good_work: 'Keep up the good work!',
      improve: "See 'Things To Do' for suggestions to maintain your health!",
      better: 'better',
      worse: 'worse',
      however: '. However,',
      and: ', and',
      decreasing: 'decreasing',
      increasing: 'increasing',
      decrease: " Lower it to make it better!",
      increase: " Increase it to make it better!",
      anomalies: "On {date} there's unusual behavior recorded on your {vital_sign}.",
      excellent: "excellent",
      good: "good",
      poor: "poor",
      low: "low",
      moderate: "moderate",
      high: "high"
    },
    onboarding: {
      homepage: {
        step1: {
          title: "Power On Button",
          description: "Click this button to power up the system",
        },
        step2: {
          title: "Start Button",
          description: "When the system is powered on, you can start scanning by clicking this button",
        },
        step3: {
          title: "Navigation Bar",
          description: `Use the navigation bar to navigate between pages.`,
        },
        step4: {
          title: "Homepage",
          description: "This button navigate you to homepage, which is the current page, where you can scan your vital signs",
        },
        step5: {
          title: "Dashboard",
          description: "This button navigate you to dashboard where you can see the overview of your scanning results",
        },
        step6: {
          title: "Supervisor View",
          description: "This button navigate you to the supervisor view page where you can see your team members' health data",
        },
        step7: {
          title: "Event Page",
          description: "This page will be coming soon!",
        },
        step8: {
          title: "Email",
          description: "This page will be coming soon!",
        },
        step9: {
          title: "Chat",
          description: "This page will be coming soon!",
        },
        step10: {
          title: "Settings Page",
          description: "This button navigate you to settings page where you can edit your personal information and set your preferences for using the system",
        },
        step11: {
          title: "Help Page",
          description: "This button will navigate you to help page for more detailed information on how to use the system",
        },
        step12: {
          title: "Logout",
          description: "This button will navigate you to logout",
        },
        step13: {
          title: "Language",
          description: "You can change language here",
        },
      },
      dashboard: {
        step1: {
          title: "General Wellness Chart",
          description: "This chart shows the overall score of your generall wellness throughout a period of time",
        },
        step2: {
          title: "Time Period",
          description: "You can change the time period to be shown in the chart here"
        },
        step3: {
          title: "Trend Line",
          description: " You can choose whether to display the trend line on the chart by clicking here",
        },
        step4: {
          title: "Vital Signs",
          description: "The complete vital signs' latest measurements can be seen here"
        },
        step5: {
          title: "Detailed Information",
          description: "You can view the detailed information of each vital sign by clicking on this button"
        },
        step6: {
          title: "Share",
          description: "Share your vital sign measurement by clicking this button"
        },
        step7: {
          title: "General Fact",
          description: "Some general fact of each vital sign can be seen by cliking this button"
        }
      }
    },
    help_content: {
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement \
        of health vital signs such as heart rate, breathing rate, blood pressure, and O2 saturation. Video \
        footage is captured through a device's camera, but no video footage is stored.",
        p2: "WVS is to be used by individuals who do not require constant or intensive health monitoring. The device \
        is not to be used as the only method for health vital signs measurement of the individuals and is not intended \
        to replace consultations with medical experts. The measurements are not served for medical purposes. Should you \
        have any further health enquiries, please contact health care professionals or other medical experts immediately."
      },
      setup: {
        title: "Set-up Guidelines",
        dos: {
          title: "Dos",
          p1: "For the best results, please do the following for the scanning:",
          1: "Put the camera near your eye level",
          2: "Prevent shaking the device ",
          3: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
          4: "Avoid backlighting ",
          5: "Keep your face well within the box and facing the camera",
          6: "Ensure suitable distance between you and the camera"
        },
        donts: {
          title: "Dont's",
          1: "Do not wear a mask",
          2: "Do not wear a hat",
          3: "Do not wear anything that will cover your face",
          4: "Do not have uneven light on you face (e.g. sunlight)",
          5: "Do not talk",
          6: "Do not make big movements",
          7: "Do not turn your head aside",
        },
        low_accuracy: {
          title: "Low Accuracy Examples",
          1: "The area is too bright or too dark",
          2: "Uneven lighting or backlighting",
          3: "The user is wearing a hat or having something cover one’s face",
          4: "The user is not facing the camera",
          5: "The distance between the user and the camera is too far",
          6: "The distance between the user and the camera is too close"
        }
      },
      operation: {
        title: "Operation",
        ground_truth: {
          title: "Measure Ground Truth with Contact-Based Device",
          p1: "The following steps should be taken before activating the WVS system so the user can compare the results from the WVS system with the results from the contact-based pulse oximeter.",
          p2: "The contact-based pulse oximeter is off until a finger is placed in it. To turn on and operate device, follow the following steps:",
          caption: `Contact-Based Device Scanning`,
          1: "Squeeze the back of the device to open it.",
          2: "Insert the index finger or ring finger into the device with the display facing up. For a left-handed person, use the left-hand index finger or ring finger. For a right-handed person, use the right-hand index finger or ring finger.",
          3: "The tip of the finger should reach the finger stop inside of the device. If the finger is not correctly positioned, repeat from Step 1.",
          4: "The device will now show your vital signs on the display.",
          p3: `For more detailed instructions on how to use the contact-based device, please refer to the manual for the device <a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">here</a>.<br>`
        },
        scanning: {
          title: "Using The Health Scanning System",
          p1: "The following steps are done to use the WVS scanning system:",
          1: "Sit comfortably in a well-lit area before scanning. Ensure the light (preferably indoor light) is evenly shed on your face. Then place the computer on a sturdy surface to prevent shaking.",
          2: `Click on the home button (the first icon) on the Navigation Bar on the left side of the WVS system. Then click on the green '<i>Power On</i>' 
          on the top right corner to start up the camera and face scanner system. The page will then display '<i>Starting up …</i>' as shown in Figure 2 below. Click allow, if prompted to enable WVS to use your camera.`,
          caption_2: "Starting up the vital sign scanner",
          3: "When the system is ready, the system will activate your device’s camera and show it in the <i>Homepage</i> along with a green box indicating your detected face. Keep your face in the center of the green frame and facing the lens while ensuring that there is an appropriate distance between your body and the camera.",
          caption_3: "Powered up system",
          4: `Click on the green circle "<i>Start</i>" button to begin the scanning process. Before beginning the scan, you will need to follow pass the <i>Scanning 
          conditions</i> shown in the face scanner and stay still while the scanner is calibrating. Once the scanning conditions are met, a three second 
          countdown will appear in the middle of the screen to show that the scan is starting.`,
          caption_4: "Conditions for scanning",
          5: `Follow the <i>Breathing guide</i> instructions that appears in the green circle throughout the scanning process. The breathing guide will change 
          between '<i>In</i>' and '<i>Out</i>' to indicate breathing in and breathing out, as shown in Figure 12 and Figure 13. The <i>Scanning progress</i> circle 
          can be seen in the scanner too. The <i>Scanning progress</i> circle indicates the remaining time till the scanning procedure is completed. `,
          caption_5: "Scanning started",
          note_5: `Note: Users are reminded to follow the in and out guide to breath at normal resting speed, although no error in the scan will be detected if the 
          breathing in and out are not matched precisely with the visual indicator.`,
          6: `The scanning progress will complete within 30 seconds, and the vital sign results will be displayed while scanning is 
          being completed. Please wait until the scan is complete to see the results. `,
          caption_6: "Basic vital signs",
          laptop: "Using Laptop",
          phone: "Using Phone",
          pc: "Using PC"
        }
      }

    },
    scan_tutorial: {
      title: "Quick Guide for Scanning on AI Wellness Scan",
      dos: "Dos:",
      donts: "Don'ts:",
      step1: {
        title: "Position Yourself Inside The Green Border",
        paragraph: "Once you click on the start button, a green human border will appear on your screen. This will guide you for a correct position and distance to make the scanning more effective. Make sure you are positioning yourself to be inside the green border.",
        image: "./assets/inside-border-en.png",
        do1: "Put the camera near your eye level",
        do2: "Keep your face well within the border and facing the camera",
        do3: "Ensure suitable distance between you and the camera",
        dont1: "Do not sit too far away from or too close to the device",
      },
      step2: {
        title: "Ensure Camera is Steady",
        paragraph: "Shaky camera may affect the reading of the scanning. Please make sure that the device is placed on a steady place and do minimum movement.",
        image: "./assets/steady-phone-en.png",
        do1: "Place device on steady surface",
        do2: "Prevent shaking the device",
        dont1: "Do not talk",
        dont2: "Do not make big movements "
      },
      step3: {
        title: "Whole Face Displayed on Camera",
        paragraph: "Make sure that your face is fully displayed on camera. Remove anything covering your face.",
        image: "./assets/whole-face-en.png",
        do1: "Face the camera",
        do2: "Make sure your face is not covered such as with hat, hoodie, mask, hair.",
        dont1: "Do not wear anything that cover your face (hat, hoodie, mask)",
        dont2: "Do not turn your face sideways"
      },
      step4: {
        title: "Ensure Even and Sufficient Lighthing on Your Face",
        paragraph: "Uneven lighting may affect the correctness of your reading. Indoor lighting is recommended to ensure correct lighting more easily",
        do1: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
        dont1: "Do not have uneven light on you face (e.g. sunlight)",
        dont2: "Backlighting"
      }
    },
    email_template: {
      email_content:
        `<html>
        
        <head></head>
        
        <body>
          <div style='width: 650px;text-align:justify;margin: auto;'>
            <div style='width: 560px;margin: auto;'>
              <p style='margin-top:3em;'>Dear {username},</p>
              <p>
                Thank you for using AI Wellness Scan. Please find attached your assessment report.
              <p>
              <p>
                For any further questions related to your health needs, please do not hesitate to contact your nearest Guardian pharmacist. You may visit <a href="https://www.guardian.com.sg/store-finder">https://www.guardian.com.sg/store-finder</a> for more details or to find the pharmacist nearest you!
              </p>
              <p>
                For general enquiries, you may contact the <b>Guardian Customer Service</b> at
              </p>
              <div style="text-align: center">
                <p>
                  Email: <a href="mailto:guardian.sg{'@'}dfiretailgroup.com">guardian.sg{'@'}dfiretailgroup.com</a>
                  <br>
                  Hours: Monday-Friday, 9:00 AM-5:30 PM
                  <br>
                  <i>(excluding Eve-of and Public Holidays)</i>
                </p>
              </div>
              <p style="color: rgba(179,174,253)">
                <i>
                  Please be advised that any personal information you provide will be used for the sole purpose of addressing your feedback.
                </i>
              </p>

              <p>Sincerely,</p>
              <p>Guardian Health & Beauty</p>
              <p style='color: #999999;margin:4em 0;'>Powered by PanopticAI</p>
            </div>
          </div>
        </body>
        
        </html>`,
      file_name: 'AIWellnessScanReport.pdf',
      email_subject: 'AI Wellness Scan Report',
    },
    health_report: {
      title: "PERSONAL HEALTH REPORT",
      poor: "Poor",
      excellent: "Excellent",
      age: "Age",
      sex: "Sex",
      female: "F",
      male: "M",
      email: "Email",
      phone_number: "Phone Number",
      height: "Height",
      weight: "Weight",
      bmi: "BMI",
      stress_level: "Stress Level",
      general_wellness: "General Wellness",
      wellness: {
        title: "Wellness",
        generalWellness: {
          title: "General Wellness",
          definition: "General wellness score is calculated through a weighted score of the core vital signs and mental stress level.",
          range: "The normal general wellness score ranges from 80-100.",
          result: "Your general wellness score is {value}."
        },
        stress: {
          title: "Stress Level",
          definition: "Stress level is a feeling of emotional or physical tension. High level of stress  may be caused by any event or thought that makes you feel frustrated, angry, or nervous.",
          range: "The normal resting stress rating is from 0-2.",
          result: "Your stress level is {value}."
        }
      },
      facial: {
        title: "Facial",
        facialSkinAge: {
          title: "Facial Skin Age",
          definition: "Facial skin is particularly thin and the most visible part of the body. Like all skin, face skin serves as an effective barrier against the external environment.",
          range: "The younger the better!",
          result: "You are young and charming!"
        }
      },
      respiratory: {
        title: "Respiratory",
        respiratoryRate: {
          title: "Respiratory Rate",
          definition: "Respiratory rate is the number of breaths a person takes per minute. High level of respiratory rates may be caused by exercise, asthma, anxiety, etc. Low level of respiratory rate may be caused by side effects from medications or an underlying medical problem.",
          range: "Normal respiration rates for an adult person at rest range from 12 to 25 breaths per minute.",
          result: "Your respiratory rate is {value} bpm."
        },
        spo2: {
          title: "Oxygen Saturation",
          definition: "Oxygen saturation is a measure of how much oxygen the red blood cells are carrying from the lungs to the rest of the body. Low level of oxygen saturation (hypoxemia) may be caused by an underlying illness that affects blood flow or breathing.",
          range: "Most people will read an SpO<sub>2</sub> of 95% or above.",
          result: "Your SpO<sub>2</sub> is {value}%."
        }
      },
      cardiovascular: {
        title: "Cardiovascular",
        heartRate: {
          title: "Heart Rate",
          definition: "Heart rate is the number of beats of the heart per minute (bpm). High level of heart rate  may be caused by anxiety, heavy caffeine/alcohol consumption, etc.",
          range: "The normal resting heart rates ranges from 60-100 bpm",
          result: "Your heart rate is {value} bpm.",
        },
        hrvSdnn: {
          title: "Heart Rate Variability SDNN",
          definition: "HRV is a measure of the variation in time between each heartbeat. SDNN is the standard deviation of the interval between normal heartbeats. Low level of heart rate variability may be caused by stress of lack of sleep.",
          range: "The heart rate variability SDNN above 100 ms are healthy.",
          result: "Your heart rate variability SDNN is {value} ms."
        },
        ibi: {
          title: "Heart Rate Variability IBI",
          definition: "HRV is a measure of the variation in time between each heartbeat. IBI is the interbeat interval. In normal heart function, each IBI value varies from beat to beat.",
          range: "Typical heart rate variability IBI values range from 500-1300 ms.",
          result: "Your heart rate variability ibi is {value} ms.",
        },
        bloodPressureSystolic: {
          title: "Systolic Blood Pressure",
          definition: "Systolic blood pressure is the peak pressure in your brachial arteries that results from the contraction of your heart muscle. Low level of blood pressure may be caused by dehydration, pregnancy, shock, endocrine disorders, etc.",
          range: "The ideal systolic blood pressure is between 90-120mmHg.",
          result: "Your systolic blood presusre is {value} mmHg.",
        },
        bloodPressureDiastolic: {
          title: "Diastolic Blood Pressure",
          definition: "Diastolic blood pressure is the amount of pressure in your brachial arteries when your heart muscle is relaxed. High blood pressure may be caused by unhealthy lifestyle or certain health conditions, such as diabetes and having obesity.",
          range: "The ideal diastolic blood pressure is between 60-80 mmHg.",
          result: "Your diastolic blood pressure is {value} mmHg."
        }
      },
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement of health vital signs such as heart rate, breathing rate, blood pressure, and O<sub>2</sub> saturation. Video footage is captured through a device's camera, but no video footage is stored.",
        p2: "The device is not to be used as the only method for health vital signs measurement of the individuals and is not intended to replace conulstations with medical experts. The measurements are not served for medical purposes. Should you have any further health enquiries, please contact health care professionals or other medical experts immediately.",
        powered_by: 'Powered by ',
        powered_by_2: ""
      }
    },
    terms_condition: {
      title: "Terms & Conditions of the AI Wellness Scan",
      description: `Please read all the Terms and Conditions below carefully before proceeding further with the “AI Wellness Scan”.`,
      content: [
        `"Hong Kong" means the Hong Kong Special Administrative Region of the People's Republic of China.`,
        `"Guardian" means Cold Storage Singapore (1983) PTE, Limited trading as Guardian.`,
        `“AI Wellness Scan” or “the Scan” is a facial scan service provided by PanopticAI Limited.`,
        `“PanopticAI” means PanopticAI Limited, a third party service provider that provides the AI Wellness Scan.`,
        `AI Wellness Scan is a camera-based, non-contact facial assessment solution provided by PanopticAI that captures images and user-provided data for instant calculation purposes.`,
        `PanopticAI will be solely responsible for collecting, processing and storing the personal data supplied by customers who use the AI Wellness Scan. Guardian does not collect, process or store any facial images, videos, and/or any other personal information of customers that use the AI Wellness Scan.`,
        `The information, functions, features and services provided by the Scan are intended as an information service and general guide only and may not cover any or all matters relating to your particular situation. in providing access to the Scan, Guardian is not practicing, administering or maintaining a practice as a clinic or professional practitioner. The Scan is not intended or provided as a personal or medical diagnosis, advice or treatment, medical service or as a medical device.`,
        `Use of the Scan is subject to the PanopticAI: (a) <a href="https://panoptic.ai/aiwellnessscan-eula-en">Terms of Use</a>, (b) <a href="https://panoptic.ai/aiwellnessscan-pics-en">Personal Information Collection Statement (PICS)</a>, and (c) <a href="https://panoptic.ai/aiwellnessscan-privacypolicy-en/">Privacy Policy</a>. By using the Scan and providing your personal data, you accept and agree to be bound by these Terms and Conditions and the PanopticAI Terms of Use, PICS and Privacy Policy.`,
        `Guardian is not obliged to notify you of the applicable terms and policies prescribed by PanopticAI from time to time. You are required to ascertain and observe those terms and policies. Guardian is not responsible for any acts or omissions of PanopticAI, including how PanopticAI collects, uses, transfers or handles your personal information or your content.`,
        `Guardian will not bear any responsibility or liability for any accuracy, reliability, and completeness of information you receive. Should you have any health-related concerns, please consult a doctor or other medical professionals.`,
        `You agree that your scan report may be shared with Guardian personnel for the purpose of discussing the scan report content to you. Guardian may provide suggestions on products available to you in response to the content of the report. However, Guardian disclaims any responsibility or liability with respect to any such suggestions, and the customer acknowledges and agrees that it is solely responsible for acting on such suggestions. To the extent permitted by law, the customer indemnifies Guardian for any liability arising as a result of the customer acting on the suggestions provided by Guardian based on or as a result of the content of the report.`,
        `Guardian reserves the right to amend these Terms and Conditions without prior notice.`,
        `These Terms and Conditions are governed by and will be construed according to Hong Kong laws. You submit to the exclusive jurisdiction of the Hong Kong courts.`,
        `No person other than you and Guardian will have any right under the Contracts (Rights of Third Parties) Ordinance to enforce or enjoy the benefit of any of the provisions of these Terms and Conditions.`
      ],
      agree_tnc: "I agree to the above Terms and Conditions.",
      agree_panoptic: "I agree to PanopticAI’s Terms of Use, PICS and Privacy Policy."
    }
  },
};

module.exports = locales;
