<template>
    <div
      class="modal fade"
      id="userProfileInput"
      tabindex="-1"
      aria-hidden="true"
      v-if="!profileFilled"
    >
        <div v-if="agreeTnC">
            <profile-form @updateProfile="updateProfile" @updateAgreeTnC="updateAgreeTnC" :form="form"/>
        </div>
        <terms-conditions @updateAgreeTnC="updateAgreeTnC" v-else/>
    </div>
</template>
  
  <script>
  import { inject } from "vue";
  import { useI18n } from "vue-i18n";
  import TermsConditions from "./terms-conditions.vue"
  import ProfileForm from "./profile-form.vue"
  
  export default {
    name: "user-profile-input",
    props: {
      form: Object,
    },
    setup() {
      const { t, locale } = useI18n();
      const {profileFilled, updateProfileFilled} = inject('profileFilled');
      return { 
        profileFilled,
        updateProfileFilled,
        t, locale
      };
    },
    components:{
        TermsConditions,
        ProfileForm
    },
    emits: ["updateProfile"],
    mounted() {
      this.agreeTnC = false
    },
    methods: {
      updateProfile(info) {
        this.$emit("updateProfile", info);
        // window.localStorage.userProfile = JSON.stringify(this.form)
      },
      updateAgreeTnC(agree) {
        this.agreeTnC = agree;
      }
    },
    computed: {
      // showGuide() {
      //     console.log("show guide")
      //     return 0
      // }
    },
    data() {
      return {
        disabled: true, 
        debug: this.$root.debugMode,
        agreeTnC: false
      };
    },
  };
  </script>
  
<style scoped>
.modal {
    overflow: scroll;
    max-height: 70%;
    max-width: 98%;
    left: 1%;
    top: 20%;
    border-radius: 10px;
}
.modal-dialog {
    width: 100%;
    /* height: 100%; */
}
.modal-close-button {
    position: absolute;
    right: 4.5%;
}
/* Modal Content/Box */
.modal-content {
    position: grid;
    background-color: #fefefe;
    margin: 0% auto;
    padding: 5px;
    border: 1px solid #888;
    /* min-height: 95%; */
    min-width: 100%;
    overflow: hidden;
}
.modal-header {
    display: flex;
    height: 15%;
    justify-content: center;
    font-size: xx-large;
}
.modal-body {
    display: grid;
    width: 100%;
    padding: 20px;
    margin: 5px auto;
}
.modal-footer {
    bottom: 0px;
    height: 15%;
    justify-content: center;
}
.inputContainer {
    width: 100%;
}
.form-heading {
    font-size: x-large;
    font-weight: bold;
    text-decoration: underline;
}
.button-big {
    /* min-height: 50px; */
    height: 80%;
    min-width: 100px;
    font-size: large;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
  
  
  