export class Camera  {
  constructor(
    video,
    options) {
      this.video = video;
      this.onFrame = options.onFrame;
      if (options.width) {
        this.width = options.width;
      }
      if (options.height) {
        this.height = options.height;
      }
      if (options.deviceId) {
        this.deviceId = options.deviceId;
      }
      
      this.requestAnimation = () => {
        window.requestAnimationFrame(this.callback)
      }

      this.callback = () => {
        this.onFrame();
        this.requestAnimation();
      }
  }

  async start() {
    this.video.srcObject = await navigator.mediaDevices.getUserMedia({
      video: {
        width: { ideal: this.width },
        height: { ideal: this.height },
        frameRate: { ideal: 60 },
        deviceId: this.deviceId
      },
    });
    this.requestAnimation();
  }
  
  async stop(){
    if (this.video) {
      this.video.srcObject.getVideoTracks().forEach( track => {track.enabled = false;track.stop();} );
      this.video.srcObject = undefined;
    }
  }

}